@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box; }

html {
  background: linear-gradient(to bottom, #2c2626, #000, #2c2626);
  font-size: 62.5%; }

body {
  position: relative;
  box-sizing: border-box;
  overflow-x: hidden;
  padding: 0; }

button {
  font-family: 'Montserrat'; }

.page {
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  padding-top: 50px;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat'; }
  .page__header {
    color: #fff;
    width: 100%;
    text-align: left;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 38px;
    margin-bottom: 16px; }
  .page__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 92%;
    height: auto; }
  .page__flex-container {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .page__flex-container--double {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column; }
      .page__flex-container--double-content {
        margin-bottom: 20px;
        height: 400px;
        width: 100%;
        background: #261e34;
        border-radius: 15px; }

@media screen and (min-width: 950px) {
  .page__container {
    max-width: 1000px; }
  .page__flex-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; } }

.form {
  display: flex;
  flex-direction: column; }
  .form__label {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px; }
  .form__input {
    background: #614d85;
    text-align: center;
    margin-bottom: 18px;
    font-size: 16px;
    border-radius: 5px;
    padding: 5px;
    outline: 0;
    border: 0;
    color: #fff; }
    .form__input::-webkit-outer-spin-button, .form__input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .form__button {
    border-radius: 25px;
    background: #07ddec;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    outline: 0;
    transition: all 0.3s ease; }
    .form__button:hover {
      cursor: pointer;
      opacity: 0.8; }

a {
  text-decoration: none; }

.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(199, 188, 188, 0.267);
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center; }
  .modal__sign-out {
    background: #261e34;
    color: #fff;
    border: none;
    font-size: 18px;
    border-radius: 50px;
    padding: 10px 15px;
    font-weight: 500;
    letter-spacing: 2px;
    width: 200px; }
    .modal__sign-out:hover {
      cursor: pointer;
      opacity: 0.9; }
  .modal__account {
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 320px; }
  .modal__select {
    border: none;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
    background: none; }
    .modal__select:focus,
    .modal__select select:focus {
      outline: none; }
  .modal__container--main {
    margin-top: 100px;
    position: fixed;
    height: 320px;
    width: 400px;
    background: linear-gradient(To left bottom, #c84ae8 10%, #07ddec);
    border-radius: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column; }
  .modal__container--secondary {
    height: 260px;
    width: 400px;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; }
    .modal__container--secondary-card {
      height: 100px;
      width: 360px;
      background: rgba(194, 187, 187, 0.466);
      border: 2px solid rgba(194, 187, 187, 0.466);
      border-radius: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: 'Montserrat';
      font-size: 1.6rem;
      font-weight: 600; }
      .modal__container--secondary-card:hover {
        cursor: pointer;
        border: 2px solid #fff; }
      .modal__container--secondary-card--metamask {
        background: url("./images/icons/metamask-icon.svg");
        background-size: cover;
        height: 60px;
        width: 70px;
        margin-left: 10px; }
      .modal__container--secondary-card--walletconnect {
        background: url("./images/icons/walletconnect-icon.svg");
        background-size: cover;
        height: 40px;
        width: 65px;
        margin-left: 10px; }
      .modal__container--secondary-card p {
        margin-left: 20px; }
  .modal__exit {
    position: absolute;
    background: rgba(194, 187, 187, 0.466);
    color: #000;
    border: none;
    padding: 10px 16px;
    font-size: 22px;
    border-radius: 60px;
    top: 10px;
    right: 10px;
    border: 2px solid rgba(194, 187, 187, 0.466); }
    .modal__exit:hover {
      cursor: pointer;
      border: 2px solid #fff; }

@media screen and (max-width: 889px) {
  .modal {
    z-index: 100; }
    .modal__container--main {
      margin-top: 0;
      height: 100%;
      width: 100vw;
      border-radius: 0;
      justify-content: flex-start;
      padding-top: 160px; } }

.alch-auction {
  width: 100%;
  height: 60px;
  background: #2b3250;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #fff; }
  .alch-auction span {
    text-decoration: underline; }
  .alch-auction p:hover,
  .alch-auction button:hover {
    cursor: pointer; }
  .alch-auction button {
    background: none;
    border: none;
    text-decoration: 0;
    margin-right: 20px;
    font-size: 20px;
    color: #fff; }

@media screen and (min-width: 750px) {
  .alch-auction {
    height: 40px; } }

.navbar {
  height: 100px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 0 25px;
  font-family: 'Montserrat';
  font-size: 1.6rem;
  letter-spacing: 1px; }
  .navbar__home {
    height: 67px;
    width: 161px;
    background-image: url("./images/alchemy-small.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .navbar__links {
    display: none; }
    .navbar__links--link {
      list-style: none;
      text-decoration: none;
      color: #fff;
      margin-right: 25px;
      transition: all 0.3s ease; }
      .navbar__links--link:hover {
        color: #07ddec; }
  .navbar__wallet {
    display: none;
    background: #07ddec;
    padding: 10px 15px;
    border-radius: 40px;
    color: #000;
    font-weight: 500;
    transition: all 0.3s ease; }
    .navbar__wallet:hover {
      opacity: 0.8; }
    .navbar__wallet--mobile {
      display: flex; }
  .navbar__wallet:hover, .navbar__home:hover, .navbar__links--link:hover {
    cursor: pointer; }

.menu-btn {
  display: flex;
  flex-direction: column;
  z-index: 30;
  cursor: pointer;
  transition: all 0.5s ease-out; }

.menu-btn .btn-line {
  width: 50px;
  border-radius: 5px;
  height: 5px;
  margin: 0 0 8px 0;
  background: #07ddec;
  transition: all 0.7s ease-out; }

.menu-btn.close {
  transform: rotate(180deg); }

.menu-btn.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(11px, 12px);
  background: #fff;
  width: 50px; }

.menu-btn.close .btn-line:nth-child(2) {
  opacity: 0; }

.menu-btn.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
  background: #fff;
  width: 50px; }

.menu {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  padding-top: 160px;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right bottom, #c84ae8, #261e34);
  transition: all 0.3s ease-out;
  z-index: 10;
  transform: translateY(0%); }

.menu__links {
  display: flex;
  flex-direction: column;
  font-size: 26px;
  font-family: 'Montserrat';
  text-align: center; }
  .menu__links--link {
    list-style: none;
    margin-top: 30px;
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease; }
    .menu__links--link:hover {
      color: #07ddec; }

ul.menu__links {
  padding: 0; }

.menu__wallet--mobile {
  font-size: 20px; }

.menu-hide {
  transform: translateY(-100%); }

@media screen and (min-width: 1000px) {
  .navbar {
    padding: 0 50px; }
    .navbar__links, .navbar__wallet {
      display: flex; }
  .menu-btn,
  .menu {
    display: none; } }

.footer {
  height: 300px;
  width: 100%;
  background: #000;
  padding: 20px 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .footer__alchemy-logo {
    display: none;
    height: 100px;
    width: 100px;
    background-image: url("./images/alchemy_square.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .footer__extra {
    height: 100px;
    width: 100px;
    display: none;
    background-image: url("./images/triangles.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.8; }
  .footer__icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 150px;
    width: 75vw; }
    .footer__icons-container--icon {
      margin: 0 10px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat; }
      .footer__icons-container--icon:hover {
        cursor: pointer;
        opacity: 0.7; }
      .footer__icons-container--icon-email {
        height: 40px;
        width: 40px;
        background-image: url("./images/icons/email.png"); }
      .footer__icons-container--icon-twitter {
        height: 50px;
        width: 50px;
        background-image: url("./images/icons/twitter.png");
        margin-bottom: 5px; }
      .footer__icons-container--icon-discord {
        height: 40px;
        width: 40px;
        background-image: url("./images/icons/discord.png"); }
      .footer__icons-container--icon-github {
        height: 40px;
        width: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("./images/icons/github.png"); }
      .footer__icons-container--icon-ethereum {
        height: 50px;
        width: 50px;
        background-image: url("./images/icons/ethereum.png"); }
      .footer__icons-container--icon-docs {
        height: 40px;
        width: 40px;
        background-image: url("./images/icons/docs.png"); }
      .footer__icons-container--icon-substack {
        height: 40px;
        width: 40px;
        background-image: url("./images/icons/substack.png"); }
      .footer__icons-container--icon-telegram {
        height: 40px;
        width: 40px;
        background-image: url("./images/icons/telegram.png"); }
      .footer__icons-container--icon-forum {
        height: 40px;
        width: 40px;
        background-image: url("./images/icons/forum.png"); }

@media screen and (min-width: 750px) {
  .footer {
    justify-content: space-between;
    height: 200px;
    padding: 20px 50px; }
    .footer__icons-container {
      justify-content: center; }
    .footer__alchemy-logo, .footer__extra {
      display: flex; } }

.stake__card {
  margin-top: 20px;
  margin-bottom: 10px;
  height: auto;
  width: 350px;
  background: #261e34;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  color: #fff; }
  .stake__card--inline-div {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .stake__card--flex-div {
    display: flex;
    height: auto;
    flex-direction: column;
    padding-bottom: 20px; }
  .stake__card--label {
    margin: 8px 0 0 30px;
    letter-spacing: 1px;
    position: relative;
    font-size: 12px; }
    .stake__card--label span {
      position: absolute;
      top: 30px;
      width: 140px;
      word-break: break-all;
      font-size: 22px;
      font-weight: 500; }
    .stake__card--label--staked {
      margin-bottom: 85px; }
    .stake__card--label--APY {
      left: -72px;
      color: rgba(255, 255, 255, 0.5); }
  .stake__card--top {
    position: relative;
    width: 100%;
    text-align: left;
    padding: 16px;
    padding-left: 20px;
    background: linear-gradient(to right bottom, #07ddec, #261e34);
    border-top-left-radius: 35px;
    border-top-right-radius: 35px; }
    .stake__card--top-uni {
      position: absolute;
      right: 30px;
      top: 16px;
      height: 51px;
      width: 50px;
      background: url("./images/icons/uni.svg");
      background-size: cover; }
    .stake__card--top-alch {
      position: absolute;
      right: 30px;
      top: 16px;
      height: 50px;
      width: 50px;
      background: url("./images/alchemy_square.png");
      background-size: cover; }
  .stake__card--title {
    font-weight: 600;
    letter-spacing: 2.5px;
    font-size: 19px; }
  .stake__card--sub-title {
    margin-top: 6px;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 15px;
    color: #2b3250; }
  .stake__card--center {
    height: 368px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; }
    .stake__card--center input {
      margin-top: 14px;
      margin-left: 30px;
      width: 150px;
      height: 30px;
      text-align: center; }
  .stake__card--small-label {
    margin-left: 30px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase; }
  .stake__card--button {
    background: #07ddec;
    border: none;
    padding: 10px 15px;
    border-radius: 15px;
    font-size: 18px;
    width: auto;
    color: #000;
    letter-spacing: 2px;
    font-weight: 600;
    transition: all 0.3s ease;
    margin: 30px;
    margin-top: 8px; }
    .stake__card--button:hover {
      cursor: pointer;
      opacity: 0.8; }
    .stake__card--button :focus {
      outline: none; }
    .stake__card--button-small {
      margin-bottom: 10px;
      background: #07ddec;
      font-size: 16px; }
  .stake__card--details {
    width: 100%;
    background-color: #261e34;
    padding: 20px;
    border-top: 1px solid rgba(7, 221, 236, 0.35);
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.75);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px; }
    .stake__card--details--button {
      text-decoration: underline;
      color: #07ddec;
      font-weight: 400;
      font-size: 15px;
      width: 100%;
      margin-left: 250px; }
      .stake__card--details--button:hover {
        cursor: pointer; }
    .stake__card--details--header {
      margin-top: 10px;
      letter-spacing: 0.5px;
      font-size: 15px; }
    .stake__card--details--text {
      margin-top: 10px;
      font-size: 14px; }
      .stake__card--details--text a {
        color: rgba(255, 255, 255, 0.75); }
        .stake__card--details--text a:hover {
          cursor: pointer; }

.stake-button--disabled {
  background: rgba(7, 221, 236, 0.3); }
  .stake-button--disabled:hover {
    cursor: not-allowed;
    opacity: 1; }

@media screen and (min-width: 750px) {
  .stake__card--title {
    font-size: 22px; } }

.landing {
  width: 100vw;
  height: auto; }
  .landing__container--main {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center; }
  .landing__container--secondary {
    position: relative;
    min-height: 600px;
    width: 100vw;
    height: auto;
    overflow: hidden;
    color: #fff;
    font-family: 'Montserrat';
    padding-bottom: 80px; }
    .landing__container--secondary--absolute-wrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 20px; }
    .landing__container--secondary-text-container {
      max-width: 1000px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      z-index: 5; }
    .landing__container--secondary-header {
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 2px;
      margin-right: 30px;
      display: block; }
    .landing__container--secondary-subheader {
      display: block;
      font-size: 19px;
      opacity: 0.6;
      margin-top: 40px;
      max-width: 80%; }
    .landing__container--secondary-button {
      border: none;
      padding: 14px 20px;
      background: #07ddec;
      font-weight: 600;
      border-radius: 20px 40px;
      font-family: 'Montserrat';
      letter-spacing: 1px;
      font-size: 19px;
      margin: 0 auto;
      margin-top: 40px;
      transition: all 0.3s ease;
      text-decoration: none;
      color: #000; }
      .landing__container--secondary-button:hover {
        cursor: pointer;
        opacity: 0.8; }
  .landing__container--tertiary {
    width: 100%;
    min-width: 540px;
    height: 160px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden; }
    .landing__container--tertiary--bottom-margin {
      width: calc(100% - 10px);
      overflow: hidden; }
    .landing__container--tertiary-triangle-normal {
      display: inline-block;
      width: 160px;
      height: 150px;
      opacity: 0.7;
      z-index: 3;
      background: linear-gradient(to bottom, #c84ae8, #07ddec);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%); }
    .landing__container--tertiary-triangle-inverse {
      display: inline-block;
      width: 160px;
      height: 150px;
      opacity: 0.7;
      z-index: 3;
      background: linear-gradient(to bottom, #c84ae8 20%, #07ddec 80%);
      clip-path: polygon(100% 0%, 0% 0%, 50% 100%); }
    .landing__container--tertiary-triangle-transform--1 {
      transform: translateX(230px); }
    .landing__container--tertiary-triangle-transform--2 {
      transform: translateX(160px) translateY(5px); }
    .landing__container--tertiary-triangle-transform--3 {
      transform: translateX(90px); }
    .landing__container--tertiary-triangle-transform--4 {
      transform: translateX(90px) translateY(10px); }
  .landing__content {
    height: 300px;
    width: 100%; }

@media screen and (min-width: 750px) {
  .landing__container--secondary {
    padding-bottom: 0; }
    .landing__container--secondary-header {
      font-size: 42px; }
    .landing__container--secondary-subheader, .landing__container--secondary-button {
      font-size: 22px; } }

.tokenize {
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  padding-top: 50px;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat'; }
  .tokenize__header {
    color: #fff;
    width: 100%;
    text-align: left;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 38px;
    margin-bottom: 16px; }
  .tokenize__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 92%;
    height: 100%; }
    .tokenize__container--flex-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column; }
      .tokenize__container--flex-wrapper--secondary {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; }
        .tokenize__container--flex-wrapper--secondary-image {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-top: 30px; }
    .tokenize__container--select {
      position: relative;
      border-radius: 15px;
      height: 350px;
      width: 100%;
      background: #261e34;
      padding: 25px; }
      .tokenize__container--select-manual {
        position: absolute;
        top: 12px;
        right: 10px;
        text-decoration: underline;
        color: #07ddec;
        font-weight: 400;
        font-size: 15px; }
        .tokenize__container--select-manual:hover {
          cursor: pointer; }
      .tokenize__container--select-label {
        height: 15%;
        width: 100%;
        color: rgba(255, 255, 255, 0.55);
        font-size: 19px;
        font-weight: 400;
        margin-bottom: 4px;
        display: flex;
        align-items: center; }
      .tokenize__container--select-manual-form {
        padding: 20px 20px 0 0;
        width: 100%;
        height: 250px;
        margin-bottom: 25px;
        position: relative; }
        .tokenize__container--select-manual-form--button {
          position: absolute;
          right: 20px;
          bottom: 20px; }
      .tokenize__container--select-secondary {
        position: relative;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 25px;
        height: 250px;
        background: #614d85;
        border: 2px solid #614d85;
        overflow-y: scroll;
        overflow-x: hidden; }
        .tokenize__container--select-secondary--connect {
          color: rgba(255, 255, 255, 0.55);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 19px;
          font-weight: 500; }
        .tokenize__container--select-secondary::-webkit-scrollbar {
          width: 0.5em; }
        .tokenize__container--select-secondary::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 5px; }
        .tokenize__container--select-secondary::-webkit-scrollbar-thumb {
          background-color: rgba(38, 30, 52, 0.8);
          border-radius: 5px; }
    .tokenize__container--image {
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      width: 100%;
      height: calc(100vw * 0.92);
      background: #000;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      flex-direction: column;
      overflow: hidden; }
      .tokenize__container--image-flex {
        height: calc(100vw * 0.92);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .tokenize__container--image--label {
        position: absolute;
        color: rgba(255, 255, 255, 0.55);
        font-size: 19px;
        font-weight: 400;
        margin: 25px;
        text-align: center; }
      .tokenize__container--image img {
        min-height: 100%;
        background-size: contain;
        z-index: 5; }
      .tokenize__container--image-info-container {
        height: 130px;
        width: 100%;
        background: #261e34;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 20px; }
        .tokenize__container--image-info-container--selected {
          color: rgba(255, 255, 255, 0.6);
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 5px; }
          .tokenize__container--image-info-container--selected-first {
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 20px; }
  .tokenize__select-component {
    display: flex;
    height: 30px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 2px;
    border-bottom: 1px solid rgba(7, 221, 236, 0.2); }
    .tokenize__select-component--checkbox {
      background: #261e34;
      opacity: 0.8;
      color: #fff;
      border: 1px solid #000;
      padding: 5px 8px;
      height: 25px;
      min-width: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Montserrat';
      font-size: 20px;
      border-radius: 5px; }
      .tokenize__select-component--checkbox:hover {
        cursor: pointer; }
    .tokenize__select-component--text {
      margin-left: 10px;
      color: #cecdcd;
      font-size: 14px; }
  .tokenize__form--button {
    border: none;
    padding: 14px 20px;
    background: #c84ae8;
    font-weight: 600;
    border-radius: 40px;
    font-family: 'Montserrat';
    letter-spacing: 1px;
    font-size: 19px;
    margin-top: 25px;
    transition: all 0.3s ease; }
    .tokenize__form--button:hover {
      cursor: pointer;
      opacity: 0.8; }

.tokenize__NTF-form {
  position: relative;
  min-height: 400px;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background: #261e34;
  padding: 25px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; }

.tokenize__NFT-form--flex-div {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center; }

.disabled {
  opacity: 0.5; }

@media screen and (min-width: 950px) {
  .tokenize {
    height: 70vh; }
    .tokenize__NTF-form {
      flex-direction: row; }
    .tokenize__container {
      max-width: 1000px; }
      .tokenize__container--select-manual {
        top: 42px;
        right: 40px; }
      .tokenize__container--image {
        height: 300px; }
      .tokenize__container--flex-wrapper {
        flex-direction: row;
        justify-content: space-between; }
        .tokenize__container--flex-wrapper--secondary {
          width: 65%; }
          .tokenize__container--flex-wrapper--secondary-image {
            width: 32%;
            margin-top: 0; }
      .tokenize__container--flex-wrapper--secondary-image {
        height: 500px; } }

.mint__form {
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  font-size: 16px; }
  .mint__form--input {
    width: 230px; }
  .mint__form--button {
    margin: 20px; }
  .mint__form input {
    text-align: center;
    font-family: 'Montserrat'; }

.mint__image-container img,
.mint__image-container video,
.mint__image-container audio {
  max-height: 300px;
  max-width: 300px;
  border-radius: 5px; }

.mint__line-container {
  width: 300px;
  margin: 0 auto;
  margin-bottom: 30px; }

.mint__container {
  position: relative;
  height: auto;
  width: 100%;
  background: #2b3250;
  border-radius: 15px;
  transition: all 0.5s ease;
  color: #fff; }
  .mint__container input {
    text-align: center; }
  .mint__container button:disabled {
    opacity: 1;
    color: #000; }
  .mint__container--header {
    font-weight: 500;
    font-size: 19px;
    padding: 20px;
    color: rgba(255, 255, 255, 0.5); }
  .mint__container--inline-div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-content: space-between;
    background: rgba(128, 128, 128, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }
  .mint__container--label {
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    font-size: 20px;
    top: 8px;
    z-index: 3;
    letter-spacing: 1px;
    font-weight: 500;
    transition: all 0.5s ease;
    right: 170px;
    pointer-events: none; }
    .mint__container--label-market {
      right: 28px; }
  .mint__container--buttons-container {
    position: relative;
    height: 40px;
    width: 300px;
    min-width: 300px;
    background: #261e34;
    top: 10px;
    right: 8px;
    border-radius: 30px; }
    .mint__container--buttons-container:hover {
      cursor: pointer; }
    .mint__container--buttons-container--secondary {
      position: absolute;
      height: 36px;
      width: 148px;
      border: solid 3px rgba(8, 196, 209, 0.7);
      top: 2.5px;
      right: 150px;
      border-radius: 30px;
      transition: all 0.5s ease;
      z-index: 5; }
      .mint__container--buttons-container--secondary:hover {
        cursor: pointer; }

.mint__top-container {
  width: 100%;
  height: 70px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.move-right {
  right: 2px; }

.color-gray {
  color: rgba(255, 255, 255, 0.3); }

.no-padding-top {
  padding-top: 0;
  padding-bottom: 0; }

@media screen and (min-width: 950px) {
  .mint__form--input {
    width: 400px; }
  .mint__container--inline-div {
    flex-direction: row;
    align-items: flex-start; } }

.dao__form {
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 120px; }
  .dao__form--input {
    width: 230px; }
  .dao__form--button {
    margin: 20px; }

.dao__container {
  position: relative;
  height: 400px;
  width: 100%;
  background: #2b3250;
  border-radius: 15px;
  color: #fff; }
  .dao__container--header {
    font-weight: 500;
    font-size: 19px;
    padding: 20px;
    color: rgba(255, 255, 255, 0.5); }
  .dao__container--inline-div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-content: space-between;
    background: rgba(128, 128, 128, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }

.dao__details-wrapper {
  width: 350px;
  height: auto; }

.dao__back-button {
  text-decoration: underline;
  color: #07ddec;
  font-weight: 400;
  font-size: 15px;
  background: none;
  outline: 0;
  border: 0;
  margin-bottom: 20px; }
  .dao__back-button:hover {
    cursor: pointer; }

.dao__wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }

.dao__details-container {
  width: 350px;
  height: auto;
  background: #2b3250;
  border-radius: 15px;
  margin-bottom: 40px;
  margin-right: 30px; }
  .dao__details-container button {
    border: 0;
    outline: 0;
    transition: all 0.3s ease; }
    .dao__details-container button:hover {
      cursor: pointer;
      opacity: 0.8; }
  .dao__details-container--button {
    background: #07ddec;
    padding: 10px 15px;
    border-radius: 40px;
    margin: 0 auto;
    width: 86px;
    font-weight: 600; }
    .dao__details-container--button-copy {
      background: #261e34;
      margin-right: 20px;
      color: rgba(255, 255, 255, 0.5);
      width: auto; }
  .dao__details-container--top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: rgba(128, 128, 128, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden; }
  .dao__details-container--header {
    font-weight: 500;
    font-size: 19px;
    padding: 20px;
    color: rgba(255, 255, 255, 0.5); }
  .dao__details-container--secondary {
    padding: 20px 20px;
    color: #fff;
    font-size: 19px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; }
    .dao__details-container--secondary > * {
      margin-bottom: 20px; }
  .dao__details-container--flex-container {
    display: flex;
    justify-content: space-between; }
  .dao__details-container--label {
    font-weight: 500; }
    .dao__details-container--label-grey {
      color: rgba(255, 255, 255, 0.4);
      word-break: break-all; }

.dao__nft-container {
  margin-top: 41px;
  width: 90%;
  height: auto;
  margin-left: 0;
  border-radius: 15px; }
  .dao__nft-container--top {
    width: 100%;
    height: 350px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    justify-content: center;
    background: #000; }
  .dao__nft-container--center {
    width: 100%;
    height: auto;
    background: #2b3250;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: rgba(255, 255, 255, 0.8);
    overflow: hidden;
    padding: 20px; }
    .dao__nft-container--center--text {
      font-weight: 500;
      font-size: 16px;
      margin-top: 8px; }
      .dao__nft-container--center--text a {
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        word-break: break-all; }
    .dao__nft-container--center--button {
      border: 0;
      outline: 0;
      width: auto;
      text-align: center;
      transition: all 0.3s ease;
      margin: 8px auto; }
      .dao__nft-container--center--button:hover {
        cursor: pointer;
        opacity: 0.8; }
    .dao__nft-container--center--flex-div {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row; }

.dao__carousel {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.carousel .slide img {
  max-height: 350px;
  max-width: 230px; }

.carousel-root {
  max-height: 350px;
  max-width: 100%; }

.slide {
  height: 350px;
  width: 230px; }

.thumbs-wrapper {
  display: none; }

@media screen and (min-width: 950px) {
  .dao__form--input {
    width: 400px; }
  .dao__wrapper {
    flex-direction: row;
    align-items: flex-start; }
  .dao__nft-container {
    max-width: 60%;
    margin-left: 25px; } }

.vote__form {
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 120px; }
  .vote__form--delegator {
    padding-bottom: 0; }
  .vote__form--input {
    width: 230px;
    font-size: 16px; }
  .vote__form--button {
    margin: 20px; }

.vote__container {
  position: relative;
  height: 400px;
  width: 100%;
  background: #2b3250;
  border-radius: 15px;
  color: #fff; }
  .vote__container--header {
    font-weight: 500;
    font-size: 19px;
    padding: 20px;
    color: rgba(255, 255, 255, 0.5); }
  .vote__container--inline-div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-content: space-between;
    background: rgba(128, 128, 128, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }

.vote__button, .vote__button--vote {
  width: 186px;
  border: 0;
  transition: all 0.3s ease;
  text-transform: uppercase; }
  .vote__button:hover, .vote__button--vote:hover {
    cursor: pointer;
    opacity: 0.8; }

.vote__button--vote {
  margin: 0;
  margin-bottom: 30px; }

.vote__function-container {
  margin-top: 41px;
  width: 92%;
  height: auto;
  margin-left: 0;
  border-radius: 15px; }
  .vote__function-container--flex-column {
    width: 35%;
    margin: 20px 0; }
  .vote__function-container--status {
    color: #07ddec; }
  .vote__function-container--label {
    font-size: 15px;
    font-weight: 500; }
  .vote__function-container--header {
    font-weight: 500;
    font-size: 19px;
    padding: 20px;
    color: rgba(255, 255, 255, 0.7); }
  .vote__function-container--top {
    width: 100%;
    background: #261e34;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between; }
  .vote__function-container--back-button {
    margin: 0;
    padding-right: 20px; }
  .vote__function-container--center {
    width: 100%;
    min-height: 230px;
    background: #2b3250;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: rgba(255, 255, 255, 0.8);
    overflow: hidden;
    padding: 20px;
    display: flex;
    flex-direction: column; }
    .vote__function-container--center > * {
      margin-bottom: 20px; }

.vote__proposal {
  min-width: 72px;
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 10px;
  background: rgba(7, 221, 236, 0.7);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  transition: 0.3s all ease; }
  .vote__proposal:hover {
    cursor: pointer;
    opacity: 0.8; }
  .vote__proposal--label {
    font-size: 16px;
    padding: 10px; }
    .vote__proposal--label-id {
      min-width: 55px; }
    .vote__proposal--label-description {
      font-size: 13px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 100px; }

.react-select {
  width: 230px;
  text-align: start;
  color: #000;
  font-weight: 500;
  font-style: 'Montserrat';
  font-size: 15px;
  background: blue;
  outline: 0; }

textarea.vote__form--input {
  max-width: 400px;
  max-height: 150px;
  font-family: 'Montserrat'; }

.flex-end {
  width: 100%;
  display: flex;
  justify-content: flex-end; }

.no-padding {
  padding: 0; }

.centered {
  text-align: center; }

.flex-centered {
  align-items: center; }

.flex-start {
  width: 100%;
  display: flex;
  justify-content: flex-start; }

@media screen and (min-width: 950px) {
  .react-select {
    width: 400px; }
  .vote__form--input {
    width: 400px; }
  .vote__container--inline-div {
    flex-direction: row;
    align-items: flex-start; }
  .vote__function-container {
    width: 60%;
    margin-left: 25px; } }

.auction__form {
  width: 100%;
  height: 100%;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .auction__form > * {
    margin-bottom: 20px; }
  .auction__form--input {
    width: 230px; }
  .auction__form--button {
    margin: 20px; }

.auction__container {
  position: relative;
  height: auto;
  width: 100%;
  background: #2b3250;
  border-radius: 15px;
  transition: all 0.5s ease;
  color: #fff; }
  .auction__container--button {
    position: absolute;
    text-decoration: underline;
    color: #07ddec;
    font-weight: 400;
    font-size: 15px;
    background: none;
    outline: 0;
    border: 0;
    top: 120px;
    right: 30px;
    margin-bottom: 20px; }
    .auction__container--button:hover {
      cursor: pointer; }
  .auction__container--header {
    font-weight: 500;
    font-size: 19px;
    padding: 20px;
    color: rgba(255, 255, 255, 0.5); }
  .auction__container--inline-div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-flow: column-reverse;
    justify-content: space-between;
    background: rgba(128, 128, 128, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }
  .auction__container--label {
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    font-size: 20px;
    top: 8px;
    z-index: 3;
    letter-spacing: 1px;
    font-weight: 500;
    transition: all 0.5s ease;
    right: 153px;
    pointer-events: none; }
    .auction__container--label-market {
      right: 38px; }
  .auction__container--buttons-container {
    position: relative;
    height: 40px;
    width: 260px;
    min-width: 260px;
    background: #261e34;
    top: 10px;
    right: 8px;
    border-radius: 30px; }
    .auction__container--buttons-container:hover {
      cursor: pointer; }
    .auction__container--buttons-container--secondary {
      position: absolute;
      height: 36px;
      width: 128px;
      border: solid 3px rgba(8, 196, 209, 0.7);
      top: 2.5px;
      right: 130.5px;
      border-radius: 30px;
      transition: all 0.5s ease;
      z-index: 5; }
      .auction__container--buttons-container--secondary:hover {
        cursor: pointer; }

.auction__media-container,
.auction__media-container img,
.auction__media-container video {
  max-height: 300px;
  max-width: 300px; }

.width-100 {
  width: 100%; }

.width-auto {
  width: auto; }

.margin-top-8 {
  margin-top: 8px; }

.move-right {
  right: 2px; }

.color-gray {
  color: rgba(255, 255, 255, 0.3); }

@media screen and (min-width: 950px) {
  .auction__form {
    padding: 30px 20px; }
    .auction__form--input {
      width: 400px; }
  .auction__container--button {
    top: 80px; }
  .auction__container--inline-div {
    flex-direction: row;
    align-items: flex-start; } }

.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.margin-top25 {
  margin-top: 25px; }

.margin-top400 {
  margin-top: 400px; }

.dark {
  background: #261e34; }

.light-blue {
  color: #07ddec; }

.ct-toast {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid gray;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  color: #000;
  margin: 0px;
  opacity: 1;
  transition: 0.3s all ease-in-out;
  min-height: 48px;
  pointer-events: all;
  font-family: 'Montserrat';
  font-weight: 500; }

.ct-group {
  align-items: center; }

.ct-icon-loading {
  width: 18px;
  height: 18px; }

.ct-icon-loading:after {
  width: 18px;
  height: 18px;
  margin: 0px;
  border-width: 3px;
  border-color: gray transparent gray transparent; }

.ct-toast.ct-toast-warn svg path,
.ct-toast.ct-toast-error svg path {
  fill: 'red'; }

.ct-toast.ct-toast-info svg path {
  fill: #d992eb; }
